.award_card {
    width: 80%;
    margin: 0 10%;
    min-height: 4rem;
    background-color: var(--color-bg-variant);
    box-sizing: border-box;
    padding: 1em;
    transition: var(--transition);
    border: 1px solid transparent;
    border-radius: 1em;
    display: flex;
    flex-wrap: wrap;
    gap: .5em;
}

.award_card:hover {
    background:transparent;
    transition: var(--transition);
    border-color: var(--color-primary);
}


.award_name {
    font-weight: 500;
    color: white;
    left: 0;
}

.award_year {
    position: relative;
    float: right;
}