.papers_container {
    height: fit-content;
    display: flex;
    flex-direction: row;
    gap: 1em;
    flex-wrap: wrap;
}

.publication_year{
    width: 80%;
    margin: 0 10%;
}

.publication_card {
    width: 100%;
    margin: 10px 0;
    min-height: 4rem;
    background-color: var(--color-bg-variant);
    box-sizing: border-box;
    padding: 1em;
    transition: var(--transition);
    border: 1px solid transparent;
    border-radius: 1em;
    display: flex;
    flex-wrap: wrap;
    gap: .5em;
}

.publication_card:hover {
    background:transparent;
    transition: var(--transition);
    border-color: var(--color-primary);
}


.paper_title {
    font-weight: 500;
    color: white;

}

.paper_author {
    font-weight: bold;
    font-style: oblique;
    color: #FFA400;
}

.haowei {
    font-weight: bold;
    color: #c1292e;
}


.paper_journel {
    color: white;
}

.link {
    background-color: var(--color-bg);
    color: white;
    padding: 0 1em;
}