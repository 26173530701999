header {
    height: 100vh;
    padding-top: 4rem;
    overflow:hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* === CTA === */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* === Header socials === */
.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    bottom: 3rem;
    left: 0;
    justify-content: center;
}

.header_socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* === Image === */
.me {
    background: var(--color-white);
    width: 22rem;
    height: fit-content;
    min-height: 330px;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 2rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    background: linear-gradient(var(--color-white),transparent);
    padding: 1.5rem 0rem 1.5rem 0rem;
}

/* === Scroll down === */
.scroll_down {
    position:absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;
}

/*====== Media queries (medium device)*/
@media screen and (max-width: 1024px) {
    header {
        height: 75vh;
        overflow: hidden;
    }
}

/*====== Media queries (small device)*/
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
        padding-top: 2rem;
    }

    .me {
        height: fit-content;
    }

    .header_socials, .scroll_down{
        display: none;
    }
}