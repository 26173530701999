footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a {
    color: var(--color-bg);
}

.permalink {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer_socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer_socials a {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border: 1px solid transparent; 
    display: flex;
}

.footer_socials a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer_copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);
}

/*====== Media queries (medium device)*/
@media screen and (max-width: 1024px) {
    
}

/*====== Media queries (small device)*/
@media screen and (max-width: 600px) {
    .permalink {
        flex-direction: column;
        gap: 1.5rem;
    }

    .footer_socials {
        margin-bottom: 2.6rem;
    }
}