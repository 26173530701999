.experience_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience_container > div {
    background-color: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience_container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience_container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 1rem;
}

.experience_details {
    display: flex;
    gap: 1rem;
    padding: 0;
    backdrop-filter: blur(15px);
}

.experience_details:hover {
    cursor: pointer;
}

div.Experienced {
    background: linear-gradient(var(--color-experience), var(--color-primary-variant));
}

div.Intermediate {
    background: linear-gradient(var(--color-middle), var(--color-primary-variant));
}

div.Begining {
    background: linear-gradient(var(--color-begin),  var(--color-primary-variant));
}

.progress_value {
    position: fixed;
    width: 100%;
    height: 100%;  
    left: 0;
    top: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--color-bg-variant);
    font-size: 1.2rem;
    font-weight: bold;
}



.skill_card {
    padding: .3rem .9rem;
    width: 100%;
    height: 4rem;
    margin: 0;
    border-radius: 1.4rem;
    transition: var(--transition);
}

.skill_card > h4 {
    font-size: 1rem;
}

.skill_card > small {
    display: block;
    font-size: 0.8rem;
    transition: var(--transition);
}

.skill_card:hover > small {
    display: none;
    transition: var(--transition);
}

.skill_card > progress {
    width: 100%;
    margin: .5rem 0 0 0;
    padding: 0;
    height: 0.5rem;
    display: block;
    position: relative;
    bottom: 0;
}

.skill_card:hover > progress::-webkit-progress-bar {
    height: 2rem;
    transition: var(--transition);
    border-radius: 1rem;
}





.skill_card > progress::-webkit-progress-bar {
    background: var(--color-light);
    height: 0.14rem;
    border: 0;
    border-radius: 4rem;
    transition: var(--transition);
}

.skill_card > progress::-webkit-progress-value {
    border: 0;
    border-radius: 4rem;
}

.Begining::-webkit-progress-value {
    background: var(--color-begin);
}

.Intermediate::-webkit-progress-value {
    background: var(--color-middle);
}

.Experienced::-webkit-progress-value {
    background: var(--color-experience);
}
/*====== Media queries (medium device)*/
@media screen and (max-width: 1024px) {
    .experience_container {
        grid-template-columns: 1fr;
    }

    .experience_container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience_content {
        padding: 1rem;
    }
}

/*====== Media queries (small device)*/
@media screen and (max-width: 600px) {
    .experience_container {
        gap: 1rem;
        margin: 0;
    }

    .experience_container > div {
        width: 100%;
        box-sizing: border-box;
        padding: 2rem 1rem;
    }

    .experience_content {
        width: 100%;
        margin: 0;
        padding: 0;

    }
}